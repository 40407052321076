<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-select
            codeGroupCd="RELATED_LAWS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="relatedLawsCd"
            label="관련법규"
            v-model="searchParam.relatedLawsCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="인허가 종류 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      :columnSetting="false"
      :isExcelDown="false"
      :isFullScreen="false"
      rowKey="limLicenseKindId"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from "@/js/selectConfig";
export default {
  name: "license-kind-pop",
  props: {
    popupParam: {
      type: Object,
      default: function () {
        return {
          type: "single",
          plantCd: "",
          chkPlantCd: "",
        };
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function () {
        return "auto";
      },
    },
  },
  data() {
    return {
      searchParam: {
        relatedLawsCd: null,
        useFlag: "Y",
        plantCd: "",
        chkPlantCd: "",
      },
      grid: {
        columns: [
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            label: "관련법규",
            align: "center",
            style: "width:350px",
            sortable: true,
          },
          {
            name: "limLicenseKindName",
            field: "limLicenseKindName",
            label: "인허가종류명",
            align: "left",
            sortable: true,
          },
        ],
        height: "600px",
        data: [],
      },
      editable: true,
      listUrl: "",
      kindlistUrl: "",
    };
  },
  computed: {},
  watch: {
    contentHeight() {
      let height =
        this.contentHeight - this.$refs["searchbox"].$el.offsetHeight - 50;
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + "px";
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.listUrl = selectConfig.sop.lim.lincense.kind.list.url;
      this.getList();
    },
    getList() {
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd;
      }
      if (this.popupParam.chkPlantCd) {
        this.searchParam.chkPlantCd = this.popupParam.chkPlantCd;
      }
      this.$http.url = this.listUrl;
      this.$http.type = "GET";
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      });
    },
    select() {
      let selectData = this.$refs["table"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내",
          message: "인허가 종류를 선택하세요", // 인허가 종류를 선택하세요
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$emit("closePopup", selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === "single") {
        this.$emit("closePopup", [row]);
      }
    },
  },
};
</script>
